import { Container, Header, Navbar, Nav } from "rsuite";
import { useState } from "react";
import HomeIcon from '@rsuite/icons/legacy/Home';
import BarChartIcon from '@rsuite/icons/BarChart';
import CogIcon from '@rsuite/icons/legacy/Cog';

function HeaderFragment({ setCurrentComponent }) {
  const [activeKey, setActiveKey] = useState(null);

  const handleNavItemClick = (eventKey, componentName) => {
    setActiveKey(eventKey);
    setCurrentComponent(componentName);
  };

  return (
    <Container>
      <Header>
        <Navbar appearance="subtle">
          <Navbar.Brand onClick={() => setCurrentComponent("")}>
            Results Web Automation APP
          </Navbar.Brand>
          <Nav activeKey={activeKey} onSelect={setActiveKey}>
            <Nav.Item
              icon={<HomeIcon />}
              eventKey="1"
              onClick={() => handleNavItemClick("1", "TableSLA")}
            >
              Home
            </Nav.Item>
            <Nav.Item
                icon={<BarChartIcon />}
                eventKey="2"
                onClick={() => handleNavItemClick("2", "TableAverage")}
            >
              Médias
            </Nav.Item>
            <Nav.Item
              eventKey="3"
              onClick={() => handleNavItemClick("3", "TableCremersTI")}
            >
              Cremers TI
            </Nav.Item>
            <Nav.Item
              eventKey="4"
              onClick={() => handleNavItemClick("4", "TableCremersGED")}
            >
              Cremers GED
            </Nav.Item>
            <Nav.Item
              eventKey="5"
              onClick={() => handleNavItemClick("5", "TableCremersEDT")}
            >
              Cremers EDT
            </Nav.Item>
            <Nav.Item
              eventKey="6"
              onClick={() => handleNavItemClick("6", "TableCremersCD")}
            >
              Cremers CD
            </Nav.Item>
            <Nav.Item
              eventKey="7"
              onClick={() => handleNavItemClick("7", "TableCremescGED")}
            >
              Cremesc GED
            </Nav.Item>
          </Nav>
          <Nav pullRight>
            <Nav.Item icon={<CogIcon />} />
          </Nav>
        </Navbar>
      </Header>
    </Container>
  );
}

export default HeaderFragment;
