import { useEffect, useState, useCallback } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import useSendData from "../../Hooks/useSendData";
import { Tooltip, Whisper, Divider, Loader, Slider } from 'rsuite';
import BlockIcon from '@rsuite/icons/Block';

function TableAverage() {
    const { sendData, loading, error, data } = useSendData();
    const [processedData, setProcessedData] = useState([]);
    const period = localStorage.getItem("period") === null || localStorage.getItem("period") === undefined || !(localStorage.getItem("period") >= 3) ? 24 : localStorage.getItem("period");
    const [value, setValue] = useState(period);

    const handleSliderChange = (value) => {
        setValue(value);
        localStorage.setItem("period", value);
    };

    const handleSliderChangeCommitted = useCallback(() => {
        const formData = new FormData();
        formData.append("period", localStorage.getItem("period"));
        sendData('automation', formData, 'POST', true);
    }, [sendData]);

    useEffect(() => {
        handleSliderChangeCommitted(value);
    }, []);

    useEffect(() => {
        const noData = <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Nulo ou não testado</Tooltip>}><BlockIcon /></Whisper>;
        if (data) {
            const newData = data.map(item => ({
                ...item,
                av_transfer_size: convert_bytes(item.av_transfer_size),
                av_loading_time: item.av_loading_time ? item.av_loading_time.toFixed(2) + " s" : noData,
                av_login_time: item.av_login_time ? item.av_login_time.toFixed(2) + " s" : noData,
                load_failure_percentage: item.load_failure_percentage + "%",
                login_failure_percentage: item.login_failure_percentage + "%",
                failure_loading: item.total_events - item.total_loading,
                failure_login: item.total_events - item.total_login,
            }))
            setProcessedData(newData);
        }
    }, [data]);

    const convert_bytes = (size_in_bytes) => {
        if (size_in_bytes < 1024)
            return `${size_in_bytes} bytes`;

        if (size_in_bytes < 1024 * 1024) {
            return `${(size_in_bytes / 1024).toFixed(2)} KB`
        }
        return `${(size_in_bytes / (1024 ** 2)).toFixed(2)} MB`;
    };

    return (
        <>
            <div>Dados das últimas {value}h</div>
            <Slider
                defaultValue={24}
                min={3}
                step={3}
                max={72}
                value={value}
                onChange={handleSliderChange}
                onChangeCommitted={() => handleSliderChangeCommitted(value)}
                graduated
                progress
                renderMark={mark => `${mark}h`}
            />
            <div className="cd-margin-botton"></div>
            {loading && <div style={{ textAlign: "center" }}><Loader size="lg" content="Carregando..." vertical /></div>}
            {error && <p>Error: {error.message}</p>}
            {!loading && !error &&
                <div>
                    <Divider><h4>Médias</h4></Divider>
                    <Table height={400} data={processedData} onRowClick={rowData => { console.log(rowData); }}>
                        <Column width={150} align="center" fixed>
                            <HeaderCell>Nome</HeaderCell>
                            <Cell dataKey="name_event" />
                        </Column>
                        <Column width={80}>
                            <HeaderCell>Medições</HeaderCell>
                            <Cell dataKey="total_events" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Média de Transferência</HeaderCell>
                            <Cell dataKey="av_transfer_size" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Média tempo Carregamento</HeaderCell>
                            <Cell dataKey="av_loading_time" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Média tempo de Login</HeaderCell>
                            <Cell dataKey="av_login_time" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Carregamentos bem sucedidos</HeaderCell>
                            <Cell dataKey="total_loading" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Carregamentos mal sucedidos</HeaderCell>
                            <Cell dataKey="failure_loading" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>% de falha carregamento</HeaderCell>
                            <Cell dataKey="load_failure_percentage" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Logins bem sucedidos</HeaderCell>
                            <Cell dataKey="total_login" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Logins mal sucedidos</HeaderCell>
                            <Cell dataKey="failure_login" />
                        </Column>
                        <Column flexGrow={2}>
                            <HeaderCell>% de falha logins</HeaderCell>
                            <Cell dataKey="login_failure_percentage" />
                        </Column>
                    </Table>
                </div>
            }
        </>
    );
}

export default TableAverage;